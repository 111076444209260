import request from '@/utils/request';
import Vue from 'vue';

export function getRechargeLists(data) {
  return request({
    url: '/recharge/lists?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}

export function getPayLists(data) {
  return request({
    url: '/recharge/getPaylists?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}

//充值
export function Recharge(data) {
  return request({
    url: '/recharge/recharge?lang=' + Vue.prototype.$lang,
    method: 'post',
    data: {
      data: Vue.prototype.$func.confusion(data),
    },
  });
}

export function getBankRecharge(data) {
  return request({
    url: '/recharge/getBankRecharge?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
export function getRechargeBankInfo(data) {
  return request({
    url: '/recharge/getRechargeBankInfo?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}

export function rechargeForBank(data) {
  return request({
    url: '/recharge/rechargeForBank?lang=' + Vue.prototype.$lang,
    method: 'post',
    data: {
      data: Vue.prototype.$func.confusion(data),
    },
  });
}
