<template>
  <div class="rechargeView">
    <van-sticky>
      <van-nav-bar
        :title="$t('充值')"
        :right-text="$t('充值记录')"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
      />
    </van-sticky>
    <div class="paylist" v-show="!showForm">
      <van-divider content-position="left">
        {{ $t('可用余额') }}
        <em
          style="color: rgb(64, 135, 241);"
        >円{{ this.$store.getters.userinfo.balance }}</em>
        ，{{ $t('请选择充值方式') }}
      </van-divider>
      <div class="RechargeList van-cell-group">
        <div
          role="button"
          tabindex="0"
          class="van-cell van-cell--clickable"
          @click="onShowPayBank()"
          v-if="showBankReacharge"
        >
          <img
            src="@/assets/images/113184.png"
            height="40"
            style="border-radius: 100%; margin-right: 10px;"
          />
          <div class="van-cell__title">
            <b>{{ $t('银行卡转账支付') }}</b>
            <div
              class="van-cell__label"
            >{{ $t('单笔最低金额为') }} 円{{ bankinfo.recharge_min }}，{{ $t('最高') }} 円{{ bankinfo.recharge_max }}，{{ $t('手续费') }} 0.00%</div>
          </div>
          <i class="van-icon van-icon-arrow van-cell__right-icon"></i>
        </div>
        <div
          role="button"
          tabindex="0"
          class="van-cell van-cell--clickable"
          v-for="(item, key) in list"
          :key="key"
          @click="onShow(item)"
        >
          <img
            src="@/assets/images/wap.png"
            height="40"
            style="border-radius: 100%; margin-right: 10px;"
          />
          <div class="van-cell__title">
            <b>{{ item.name }} {{ $t('在线支付') }}</b>
            <div
              class="van-cell__label"
            >{{ $t('单笔最低金额为') }} 円{{ item.min_price }}，{{ $t('最高') }} 円{{ item.max_price }}，{{ $t('手续费') }} {{ item.rate }}%</div>
          </div>
          <i class="van-icon van-icon-arrow van-cell__right-icon"></i>
        </div>
      </div>
    </div>
    <div class="content_box text-center" v-show="showForm">
      <h2 class="van-title text-left">{{ $t('充值金额') }}</h2>
      <van-cell-group>
        <van-field v-model="money" :placeholder="$t('请输入充值金额')" />
      </van-cell-group>
      <h2 class="van-title text-left" v-if="type == 0">{{ $t('付款姓名') }}</h2>
      <van-cell-group v-if="type == 0">
        <van-field
          border
          v-model="remark"
          rows="2"
          autosize
          type="textarea"
          maxlength="100"
          :placeholder="$t('备注转账姓名')"
          show-word-limit
        />
      </van-cell-group>
      <h2 class="van-title text-left" v-if="type != 0">{{ $t('您的姓名') }}</h2>
      <van-cell-group v-if="type != 0">
        <van-field v-model="name" :placeholder="$t('请输入您的姓名')" />
      </van-cell-group>
      <h2 class="van-title text-left" v-if="type != 0">{{ $t('您的手机号') }}</h2>
      <van-cell-group v-if="type != 0">
        <van-field v-model="mobile" :placeholder="$t('请输入您的手机号')" />
      </van-cell-group>
      <h2 class="van-title text-left" v-if="type != 0">{{ $t('您的UPI账号') }}</h2>
      <van-cell-group v-if="type != 0">
        <van-field v-model="account" :placeholder="$t('请输入您的UPI账号')" />
      </van-cell-group>
      <h2 class="van-title text-left" v-if="type != 0">{{ $t('您的邮箱') }}</h2>
      <van-cell-group v-if="type != 0">
        <van-field v-model="email" :placeholder="$t('请输入您的邮箱')" />
      </van-cell-group>
      <div style="padding: 20px 0;">
        <van-button
          type="info"
          block
          color="linear-gradient(90deg,#4b34c3,#3d3ff7)"
          @click="submitHandler"
        >{{ $t('立即充值') }}</van-button>
        <van-button type="default" block color="#fff" @click="showForm = false">{{ $t('返回') }}</van-button>
      </div>
    </div>

    <van-popup
      v-model="show"
      :close-on-click-overlay="false"
      position="bottom"
      :style="{ height: '100%' }"
      class="showpaybank"
    >
      <van-nav-bar :title="$t('付款详情')" :right-text="$t('关闭')" @click-right="onClickPayBankRight" />
      <div class="pay_box">
        <van-field v-model="bank_name" center clearable :label="$t('银行名称') + ':'" readonly>
          <template #button>
            <van-button size="mini" type="primary" @click="doCopyCode(bank_name)">{{ $t('复制') }}</van-button>
          </template>
        </van-field>
        <van-field v-model="bank_number" center clearable :label="$t('银行卡号') + ':'" readonly>
          <template #button>
            <van-button size="mini" type="primary" @click="doCopyCode(bank_number)">{{ $t('复制') }}</van-button>
          </template>
        </van-field>
        <van-field v-model="bank_account" center clearable :label="$t('姓名') + ':'" readonly>
          <template #button>
            <van-button size="mini" type="primary" @click="doCopyCode(bank_account)">{{ $t('复制') }}</van-button>
          </template>
        </van-field>
        <van-field v-model="bank_addr" center clearable :label="$t('支行名称') + ':'" readonly>
          <template #button>
            <van-button size="mini" type="primary" @click="doCopyCode(bank_addr)">{{ $t('复制') }}</van-button>
          </template>
        </van-field>
          <van-field v-model="bank_numer" center clearable :label="$t('支行编号') + ':'" readonly>
          <template #button>
            <van-button size="mini" type="primary" @click="doCopyCode(bank_numer)">{{ $t('复制') }}</van-button>
          </template>
        </van-field>      
        <van-field v-model="money" center clearable :label="$t('充值金额') + ':'" readonly></van-field>
        <van-field v-model="remark" center clearable :label="$t('付款姓名') + ':'" readonly></van-field>
        <van-field :label="$t('充值凭证') + ':'">
          <template #input>
            <div style="text-align:left">
              <van-uploader
                v-model="fileList"
                :max-count="1"
                preview-size="60"
                :before-read="beforeRead"
              />
            </div>
          </template>
        </van-field>
        <div style="padding: 20px 0;">
          <van-button
            type="info"
            block
            color="linear-gradient(90deg,#4b34c3,#3d3ff7)"
            @click="onPayBank"
          >{{ $t('提交') }}</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import {
  getPayLists,
  Recharge,
  getBankRecharge,
  getRechargeBankInfo,
  rechargeForBank
} from "@/api/recharge";

export default {
  name: "recharge",
  data() {
    return {
      money: "",
      remark: "",
      showForm: false,
      list: [],
      data: [],
      bankinfo: [],
      showBankReacharge: false,
      type: 0,
      show: false,
      bank_name: "",
      bank_account: "",
      bank_number: "",
      bank_addr: "",
      bank_numer: "",
      fileList: [],
      bankinfo: [],
      name: "",
      mobile: "",
      account: "",
      email: ""
    };
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.GetBankRecharge();
    this.GetPayLists();
  },
  methods: {
    onClickPayBankRight() {
      this.show = false;
    },
    onClickRight() {
      this.$router.push("/main/user/wallet");
    },
    onClickLeft() {
      if (this.showForm == true) {
        this.showForm = false;
      } else {
        this.$router.go("-1");
      }
    },
    onShow(data) {
      this.data = data;
      this.type = 1;
      this.showForm = true;
    },
    onShowPayBank() {
      this.showForm = true;
      this.type = 0;
    },
    // 返回布尔值
    beforeRead(file) {
      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        this.$toast("图片格式不正确，请确认");
        return false;
      }
      return true;
    },
    doCopyCode: function(str) {
      this.$copyText(str).then(
        e => {
          this.$toast({
            message: this.$t("复制成功"),
            duration: 500,
            forbidClick: true,
            position: "bottom"
          });
        },
        e => {
          this.$toast({
            message: this.$t("复制失败"),
            duration: 500,
            forbidClick: true,
            position: "bottom"
          });
        }
      );
    },
    GetRechargeBankInfo() {
      getRechargeBankInfo({})
        .then(response => {
          this.bankinfo = response.result;
          this.bank_account = this.bankinfo.bank_account;
          this.bank_number = this.bankinfo.bank_number;
          this.bank_name = this.bankinfo.bank_name;
          this.bank_addr = this.bankinfo.bank_addr;
          this.bank_numer = this.bankinfo.bank_numer;
          this.show = true;
        })
        .catch(error => {});
    },
    GetPayLists() {
      getPayLists({ lang: this.$lang })
        .then(response => {
          this.list = response.result;
        })
        .catch(error => {});
    },
    GetBankRecharge() {
      getBankRecharge({ lang: this.$lang })
        .then(response => {
          this.bankinfo = response.result;
          if (this.bankinfo.recharge_status == "open") {
            this.showBankReacharge = true;
          }
        })
        .catch(error => {});
    },
    onPayBank() {
      if (!this.fileList[0]) {
        this.$toast({
          message: this.$t("请上传付款凭证"),
          duration: 500,
          forbidClick: true,
          position: "bottom"
        });
        return;
      }
      rechargeForBank({
        money: this.money,
        bank_id: this.bankinfo.id,
        remark: this.remark,
        image: this.fileList[0].content
      })
        .then(response => {
          this.$toast({
            message: response.msg,
            duration: 500,
            forbidClick: true
          });
          setTimeout(() => {
            this.$router.replace("/main/user/wallet");
          }, 500);
        })
        .catch(error => {});
    },
    submitHandler(e) {
      if (this.type == 0) {
        if (parseInt(this.money) < parseInt(this.bankinfo.recharge_min)) {
          this.$toast({
            message:
              this.$t("单笔最低金额为") + " 円" + this.bankinfo.recharge_min,
            duration: 500,
            forbidClick: true,
            position: "bottom"
          });
          return;
        }
        if (this.remark == "") {
          this.$toast({
            message: this.$t("备注转账姓名"),
            duration: 500,
            forbidClick: true,
            position: "bottom"
          });
          return;
        }
        this.GetRechargeBankInfo();
      } else {
        if (
          this.money == "" ||
          this.name == "" ||
          this.mobile == "" ||
          this.account == "" ||
          this.email == ""
        ) {
          this.$toast({
            message: this.$t("请完善付款信息"),
            duration: 500,
            forbidClick: true,
            position: "bottom"
          });
          return;
        }
        if (parseInt(this.money) < parseInt(this.data.min_price)) {
          this.$toast({
            message: this.$t("单笔最低金额为") + " 円" + this.data.min_price,
            duration: 500,
            forbidClick: true,
            position: "bottom"
          });
          return;
        }
        this.$toast.loading({
          message: "loading...",
          forbidClick: true,
          duration: 0
        });
        Recharge({
          money: this.money,
          name: this.name,
          mobile: this.mobile,
          account: this.account,
          email: this.email,
          id: this.data.id
        })
          .then(response => {
            this.$toast.clear();
            this.showForm = false;
            this.money = "";
            this.name = "";
            this.account = "";
            this.email = "";
            this.mobile = "";
            window.open(response.result.payurl);
          })
          .catch(error => {});
      }
    }
  }
};
</script>

<style lang="stylus" rel="stylesheet/stylus">
.rechargeView
  .paylist
    .van-divider
      font-size: 0.32rem
      display: -webkit-box
  .van-cell
    align-items: center
    background-color: #151d31
    color: #bbb
    margin-bottom: 0.1rem
  .content_box
    margin: 0.3rem
    .van-cell
      padding: 0.1rem 0.42667rem
      background-color: #151d31
      color: #bbb
    .van-title
      padding: 0.3rem 0
      font-size: 0.4rem
    .van-button
      font-size: 0.46rem
      border-radius: 0.2rem
    .van-button--default
      color: #000 !important
      margin-top: 0.3rem
      border: 0.0625rem solid #ebedf0
    h2
      color: #bbb
    .van-icon::before
      color: #bbb
  .showpaybank
    .pay_box
      padding: 0 2%
    .van-nav-bar
      margin-bottom: 0.3rem
    .van-cell
      padding: 0.15rem 0.3rem
      margin-bottom: 0.2rem
      .van-field__label
        color: #bbb
</style>
